import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import * as _ from 'lodash';
import { Observable, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { IStatuspalSummaryResponse } from './statuspal.model';
import { UiTranslateService } from '../lang/ui-translate.service';
import { DateTimeService } from '@gridscale/gs-services';


export interface IIncident {
  component_id?: string,
  created_at?: string,
  deleted_at?: string,
  human_status?: string,
  id?: number,
  message: string,
  name: string,
  scheduled_at: string,
  scheduled_at_date?: string,
  scheduled_today?: boolean,
  status: number | string,
  /*
  0   Scheduled       This status is reserved for a scheduled status.
  1   Investigating   You have reports of a problem and you're currently looking into them.
  2   Identified      You've found the issue and you're working on a fix.
  3   Watching        You've since deployed a fix and you're currently watching the situation.
  4   Fixed           The fix has worked, you're happy to close the incident.
  */
  statusClass?: string,
  time_left?: string,
  updated_at?: string,
  visible?: number,
}

export interface IOveralStatus {
  text: string;
  textLong: string;
  online: boolean;
  issues: boolean;
}

export interface IComponentStatus {
  created_at: string;
  deleted_at?: string;
  description: string;
  enabled: boolean;
  group_id: number;
  id: number;
  link: string;
  name: string;
  order: number;
  status: string | number;
  /*
  1   Operational         The component is working.
  2   Performance Issues  The component is experiencing some slowness.
  3   Partial Outage      The component may not be working for everybody. This could be a geographical issue for example.
  4   Major Outage        The component is not working for anybody.
  */
  status_name: string;
  tags: string[];
  updated_at: string;
}

@Injectable({
  providedIn: 'root'
})
export class StatuspalService {
  translationKeys = {
    now: 'now',
    platformstatus_online: 'platformstatus_online',
    platformstatus_performance_issues: 'platformstatus_performance_issues',
    platformstatus_performance_issues_long: 'platformstatus_performance_issues_long',
    platformstatus_partial_outage: 'platformstatus_partial_outage',
    platformstatus_partial_outage_long: 'platformstatus_partial_outage_long',
    platformstatus_major_outage: 'platformstatus_major_outage',
    platformstatus_major_outage_long: 'platformstatus_major_outage_long'
  };

  private httpClient = inject(HttpClient);
  private translate = inject(UiTranslateService);
  private dateTimeService = inject(DateTimeService);

  private cachedResponse?: Partial<IStatuspalSummaryResponse>;
  private cachedStatusResponse?: Partial<IStatuspalSummaryResponse>;

  private fetchOrSubscribe(statusApi: string) {
    if (this.cachedResponse !== undefined) {
      return of(this.cachedResponse);
    }


    return this.httpClient.get<Partial<IStatuspalSummaryResponse>>(statusApi + '?t=' + Date.now()).pipe(
      tap(response => this.cachedResponse = response)
    );
  }

  /**
   * fetch incidents from API when the api endpoint is set in environment
   * @param statusApi: string - host of the status API
   */
  fetch(statusApi: string): Observable<IIncident[]> {
    return new Observable((_observer) => {
      if (!_.isEmpty(statusApi)) {
        this.fetchOrSubscribe(statusApi).pipe(
          catchError((_e: HttpErrorResponse) => {
            _observer.error(_e);
            throw 'Request error ' + _e.message;
          }),
        ).subscribe(_res => {
          const incidents = [...(_res.incidents || []), ...(_res.maintenances || []), ...(_res.upcoming_maintenances || [])];
          const ret: IIncident[] = [];
          incidents.forEach(incident => {
            let human_status = 'scheduled';
            let status = 0;

            switch (incident.updates[0].type) {
              case 'issue':
              case 'investigating':
                human_status = 'investigating';
                status = 1;
                break;

              case 'monitoring':
              case 'retroactive':
              case 'escalate':
                human_status = 'watching';
                status = 3;
                break;

              case 'update':
              case 'resolve':
                human_status = 'identified';
                status = 2;
                break;
            }
            ret.push({
              created_at: incident.starts_at,
              human_status,
              id: incident.id,
              message: incident.updates[0]?.description,
              name: incident.title,
              scheduled_at: incident.starts_at,
              scheduled_today: false,
              status,
              statusClass: human_status,
              updated_at: incident.updated_at,
              visible: 1,
            })
          })

          _observer.next(ret);
          _observer.complete();
        });

      } else {
        // fetching incidents is disabled by not-given API endpoint
        _observer.next([]);
      }
    });
  }


  /**
   * get only the display worthy incidents (scheduled in the future, are acute etc.)
   * @param statusApi: string - host of the status API
   */
  getImportant(statusApi: string): Observable<IIncident[]> {
    return new Observable((_observer) => {
      this.fetch(statusApi).subscribe((_incidents: IIncident[]) => {
        const incidents: IIncident[] = [];

        _.forEach(_incidents, (_incident: IIncident) => {
          if ((_incident.status == 0 && _.has(_incident, 'scheduled_at')) || _.has(_incident, 'created_at')) {
            const day = new Date(Date.parse(_incident.status == 0 ? _incident.scheduled_at + 'Z' : _incident.created_at + 'Z'));
            const updated = new Date(Date.parse(_incident.updated_at + 'Z'));
            const before8Hours = new Date();
            before8Hours.setHours(before8Hours.getHours() - 8);
            const before2Hours = new Date();
            before2Hours.setHours(before2Hours.getHours() - 2);

            if (
              (_incident.status == 0 && (day && day.getTime() > before8Hours.getTime()) ||
                (updated && updated.getTime() > before2Hours.getTime())) // scheduled Incidents should be displayed when schedule date is in future or up to 8 hours in past, or if updated within the last 2 hours
              ||
              (updated && updated.getTime() > before8Hours.getTime()) // no scheduled incidents should be displayed up to 8 hours after last update
            ) {
              // is in Future

              let isToday = false;
              let timeLeft = "";
              const date1DayAfter = new Date();
              date1DayAfter.setDate(date1DayAfter.getDate() + 1);

              if (!(day.getTime() > date1DayAfter.getTime())) {
                // is today
                isToday = true;
                timeLeft = this.dateTimeService.fromNow(day);
                if (day.getTime() < (new Date()).getTime() && _incident.status == 0) {
                  timeLeft = this.translate.instant(this.translationKeys.now);
                }
              }

              incidents.push({
                id: _incident.id,
                scheduled_at: day.toLocaleString(),
                scheduled_at_date: _incident.status == 0 ? _incident.scheduled_at + 'Z' : _incident.created_at + 'Z',
                scheduled_today: isToday,
                time_left: timeLeft,
                name: (_.has(_incident, 'name') ? _incident.name : "Incident"),
                message: (_.has(_incident, 'message') ? _incident.message : ""),
                status: parseInt(_incident.status as unknown as string),
                statusClass: _incident.human_status !== undefined ? _incident.human_status!.toLowerCase() : ''
              });
            }
          }
        });

        _observer.next(incidents);
        _observer.complete();

      }, (_e) => {
        _observer.error(_e);
      })
    });

  }

  /**
   * resolves the overal platform status
   * @param string _statusApi - the url to the status api where cacheHQ runs
   * @return Observable
   */
  getOveralStatus(_statusApi: string): Observable<IOveralStatus> {
    return new Observable(_observer => {

      let obs: Observable<Partial<IStatuspalSummaryResponse>>;
      if (this.cachedStatusResponse !== undefined) {
        obs = of(this.cachedStatusResponse);
      } else {
        obs = this.httpClient.get<Partial<IStatuspalSummaryResponse>>(_statusApi + '?t=' + Date.now()).pipe(
          tap(result => this.cachedStatusResponse = result)
        );
      }

      obs.pipe(
        catchError((_e: HttpErrorResponse) => {
          _observer.error(_e);
          throw 'Request error' + _e.message;
        }),
        map(_res => {
          if (_res?.status_page?.current_incident_type !== null) {
            if (_res?.status_page?.current_incident_type === 'scheduled') {
              // Maintenance

              if (_res?.maintenances?.length) {
                const data = _res?.maintenances[0];
                return {
                  created_at: data.starts_at,
                  description: data.updates[0]?.description,
                  enabled: true,
                  group_id: data.service_ids[0],
                  id: data.id,
                  link: data.url,
                  name: data.title,
                  order: 0,
                  status: 1,
                  status_name: 'FooBar',
                  tags: [],
                  updated_at: data.updated_at
                };
              }
            } else if (_res?.incidents?.length) {
              // Incident
              const data = _res?.incidents[0];

              return {
                created_at: data.starts_at,
                description: data.updates[0]?.description,
                enabled: true,
                group_id: data.service_ids[0],
                id: data.id,
                link: data.url,
                name: data.title,
                order: 0,
                status: data.type === 'minor' ? '3' : '4',
                status_name: 'FooBarIncident',
                tags: [],
                updated_at: data.updated_at
              };
            }
          }

          return null;


        })
      ).subscribe(
        _componentStatus => {
          const overalStatus: IOveralStatus = {
            text: 'Online',
            textLong: this.translate.instant(this.translationKeys.platformstatus_online),
            online: true,
            issues: false
          };

          if (_componentStatus?.status) {
            switch (_componentStatus.status) {
              case '2':
                overalStatus.text = this.translate.instant(this.translationKeys.platformstatus_performance_issues);
                overalStatus.textLong = this.translate.instant(this.translationKeys.platformstatus_performance_issues_long);
                overalStatus.online = true;
                overalStatus.issues = true;
                break;

              case '3':
                overalStatus.text = this.translate.instant(this.translationKeys.platformstatus_partial_outage);
                overalStatus.textLong = this.translate.instant(this.translationKeys.platformstatus_partial_outage_long);
                overalStatus.online = true;
                overalStatus.issues = true;
                break;

              case '4':
                overalStatus.text = this.translate.instant(this.translationKeys.platformstatus_major_outage);
                overalStatus.textLong = this.translate.instant(this.translationKeys.platformstatus_major_outage_long);
                overalStatus.online = false;
                overalStatus.issues = false;
                break;
            }
          }

          _observer.next(overalStatus);
          _observer.complete();
        },
        _e => {
          _observer.error(_e);
        }
      );
    });
  }



  get lang() {
    return (this.translate.currentLang || this.translate.defaultLang).toLowerCase();
  }
}
